<template>
  <div>

    <!-- Верхний баннер -->
    <section class="section usr-sctn-bgrnd">
      <div class="container has-text-centered">
        <div class="columns is-block">
          <div style="height:5rem;"></div>
          <div class="column">
            <h1 class="title is-2 has-text-white" v-html="$t('GT banner text')"></h1>
          </div>
          <div style="height:6rem;"></div>
        </div>
      </div>
    </section>
<!--asd-->
    <section class="section">
      <div class="container has-text-centered">
        <div style="height:1rem;"></div>
        <div class="columns">
          <div class="column is-two-fifths has-text-right">
            <img src="@/assets/icons/cert.svg" style="height: 200px">
          </div>
          <div class="column is-two-fifths">
            <div class="column">
              <a class="button button-order is-success is-mobile-small is-large " v-on:click="Modal">{{ $t('Order a digital signature certificate') }}</a>
            </div>
            <div class="column">
              <router-link to="/getrevoke" class="button button-order is-success is-large is-mobile-small">{{ $t('Revoke a digital signature certificate') }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- FAQ -->
    <section class="section usr-sctn-bgrnd-green">
      <div class="container has-text-centered">
        <h1 class="title is-3 ">{{ $t('FAQ') }}</h1>
        <div style="height:0.75rem;"></div>

        <div class="columns is-centered">
          <div class="column is-two-fifths">
            <div class="box rounded">
              <div class="media">
                <div class="media-left">
                  <figure class="image is-48x48">
                    <img src="@/assets/img/pass.png">
                  </figure>
                </div>
                <div class="media-content is-center">
                  <div class="content has-text-centered">
                    <router-link to="keyproblem">{{ $t('Have you forgotten your password or security token is locked') }}?</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns is-centered">
          <div class="column is-two-fifths">
            <div class="box rounded">
              <div class="media">
                <div class="media-left">
                  <figure class="image is-48x48">
                    <img src="@/assets/img/samruk.png">
                  </figure>
                </div>
                <div class="media-content is-center">
                  <div class="content has-text-centered">
                    <router-link to="samruk">{{ $t('Do you have problems accessing the Samruk-Kazyna e-procurement portal') }}?</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns is-centered">
          <div class="column is-two-fifths">
            <div class="box rounded">
              <div class="media">
                <div class="media-left">
                  <figure class="image is-48x48">
                    <img src="@/assets/img/check.png">
                  </figure>
                </div>
                <div class="media-content is-center">
                  <div class="content has-text-centered">
                    <router-link to="keycheck">{{ $t('How to check your security token') }}?</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns is-centered">
          <div class="column is-two-fifths">
            <div class="box rounded">
              <div class="media">
                <div class="media-left">
                  <figure class="image is-48x48">
                    <img src="@/assets/img/faq.png">
                  </figure>
                </div>
                <div class="media-content is-center">
                  <div class="content has-text-centered">
                    <router-link to="/FAQ">{{ $t('Other Frequently Asked Questions') }}</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>

    <!-- Модальное окно при заказе сертификата -->
    <div class="modal is-active" v-if="orderModalActive">
      <div class="modal-background" v-on:click="Modal"></div>
      <div class="modal-card">
        <header class="modal-card-head"><p class="modal-card-title">{{ $t('Do you purchase a certificate from us for the first time') }}?</p>
<!--          <figure class="image is-48x48">-->
<!--            <img src="@/assets/img/certificate.png" alt="Image">-->
<!--          </figure>-->
        </header>
        <section class="modal-card-body">
          <div style="height: 1em;"></div>
          <div class="has-text-centered">
            <router-link :to="{path: '/getcertificate', name: 'getcertificate', params: {step: 'button-yes'}}" class="button is-primary button-yes">{{ $t('Yes') }}</router-link>
            <router-link :to="{path: '/getcertificate', name: 'getcertificate', params: {step: 'button-no'}}" class="button is-primary button-no">{{ $t('No') }}</router-link>
          </div>
          <div style="height: 1em;"></div>
        </section>
        <footer class="modal-card-foot">
        </footer>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      orderModalActive: false
    }
  },
  methods: {
    Modal () {
      this.orderModalActive = !this.orderModalActive
      window.localStorage.setItem('order', '')
      window.localStorage.setItem('OrderNumber', '')
      window.localStorage.setItem('OrderPassword', '')
      window.localStorage.setItem('currency', '')
      window.localStorage.setItem('currencyId', '')
      window.localStorage.setItem('price', '')
      window.localStorage.setItem('course', '')
      window.localStorage.setItem('template', '')
      window.localStorage.setItem('templateId', '')
      window.localStorage.setItem('templateName', '')
      window.localStorage.setItem('templateData', '')
      window.localStorage.setItem('templateValidity', '')
    }
  }
}
</script>

<style scoped lang="scss">
  .is-center {
    margin: auto;
  }
  .rounded {
    border-radius: 50px;
  }
  .usr-sctn-bgrnd {
    background: url(../assets/img/mnbnnr.jpg) center center;
    background-size: cover;
    background-color: black;
  }
  .usr-sctn-bgrnd-2 {
    background: url(../assets/img/main_banner2.jpg) center center;
    background-size: cover;
    background-color: silver
  }
  .usr-sctn-bgrnd-green {
    background-color: #e2f4e8;
  }
  .is-2 {
    font-size: 2.2rem;
  }
  .button-order {
    min-width: 360px;
  }
  .button-yes, .button-no {
    width: 80px;
  }
  .button-yes {
    margin-right: 30px;
  }


  @media (max-width: 768px) {
    .button.is-large {
      font-size: 18px;
      min-width: 100%;
    }
  }

</style>
